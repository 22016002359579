import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScrollToAndFocus } from '../../../hooks/useScrollToAndFocus';
import { Roles } from '../../../models/Role';
import { Translations } from '../../../models/Translation';
import ObjectUtils from '../../../utils/ObjectUtils';
import { Option } from '../../Option';
import ContextMenu from '../ContextMenu';
import Checkbox from '../form-control/Checkbox';
import RadioButton from '../form-control/RadioButton';
import TranslatableInput from '../form-control/TranslatableInput';
import CheckIcon from '../icon/CheckIcon';
import XIcon from '../icon/XIcon';
import { ContextMenuItem } from '../ContextMenu';
import EditIcon from '../icon/EditIcon';
import DeleteIcon from '../icon/DeleteIcon';
import { ToastType, useToasts } from '../../../contexts/ToastContext';
import Button, { ButtonType } from '../form-control/Button';
import { PickListModalItemType } from './PickListTypes';
import PickListService from '../../../services/PickListService';
import { EventSystem } from '../../../events/EventSystem';
import { PickListItem } from '../../../models/Picklist';
import usePermissions from '../../../hooks/permissions/usePermissions';
import LanguageUtils from '../../../utils/LanguageUtils';

export type RadioButtonOption = Option<string, boolean> & { subText?: string };

type PickListModalItemsProps = {
  options: PickListModalItemType[];
  multi?: boolean;
  className?: string;
  showAddNewEditor?: boolean;
  hideNewEditorDescription?: boolean;
  picklistSourceType?: string;
  onAddNew: (translations: Translations | null) => void;
  onChange: (options: PickListModalItemType[]) => void;
  visibilityFilter?: (item: RadioButtonOption) => boolean;
  onEditingStart: () => void;
  onEditingEnd: () => void;
};

export const PickListModalItems: FC<PickListModalItemsProps> = (props) => {
  const {
    className,
    options,
    multi,
    onChange,
    visibilityFilter,
    showAddNewEditor,
    onAddNew,
    hideNewEditorDescription,
    picklistSourceType,
    onEditingStart,
    onEditingEnd,
  } = props;

  const [newTranslations, setNewTranslations] = useState<Translations>({});
  const [editingItem, setEditingItem] = useState<PickListItem | null>(null);
  const newItemTitleRef = useRef<HTMLInputElement>(null);
  const newItemContainerRef = useRef<HTMLDivElement>(null);
  const triggerScrollToNew = useScrollToAndFocus(newItemTitleRef, newItemContainerRef);
  const hasPermission = usePermissions();

  const { t } = useTranslation('common');
  const toasts = useToasts();

  useEffect(
    function triggerScrollToInput() {
      if (showAddNewEditor) {
        onEditingStart();
        setTimeout(() => {
          triggerScrollToNew();
        }, 100);
      }
    },
    [onEditingStart, showAddNewEditor, triggerScrollToNew],
  );

  useEffect(() => {
    if (!showAddNewEditor) {
      setNewTranslations({});
    }
  }, [showAddNewEditor]);

  const onRadioButtonChanged = (id: string, value: boolean): void => {
    let newOptions = ObjectUtils.DeepClone(options);

    if (!multi) {
      newOptions = newOptions.map((option) => ({ ...option, value: false }));
    }

    const option = newOptions.find((x) => x.id === id);
    if (option) {
      option.value = value;
    }
    onChange(newOptions);
  };

  const doAddNew = useCallback(() => {
    onEditingEnd();
    onAddNew(newTranslations);
    setNewTranslations({});
  }, [newTranslations, onAddNew, onEditingEnd]);

  const displayOptions = useMemo(() => (visibilityFilter ? options.filter(visibilityFilter) : options), [options, visibilityFilter]);

  const reinstatePicklistItem = useCallback(
    (item: PickListModalItemType) => {
      PickListService.reinstatePicklistItem(item.id).then(() => {
        picklistSourceType && EventSystem.fireEvent('picklist-updated', { sourceType: picklistSourceType });
      });
    },
    [picklistSourceType],
  );

  const contextItems = useCallback(
    (item: PickListModalItemType) => {
      if (!item.isCustom || !hasPermission(Roles.TeamLead)) {
        return [] as ContextMenuItem[];
      }

      return [
        {
          title: t('picklist.context-menu.edit'),
          icon: <EditIcon className="h-6 w-6" />,
          onClick() {
            onEditingStart();
            setEditingItem({
              id: item.id,
              isCustom: !!item.isCustom,
              isDisabled: !!item.isDisabled,
              translations: item.translations || {},
            });
          },
        },
        {
          title: t('picklist.context-menu.remove'),
          icon: <DeleteIcon className="h-6 w-6" />,
          onClick() {
            PickListService.deleteItem(item.id).then(() => {
              picklistSourceType && EventSystem.fireEvent('picklist-updated', { sourceType: picklistSourceType });
              toasts.addToast({
                title: t('picklist.toasts.remove.title'),
                description: t('picklist.toasts.remove.description', { title: item.text }),
                type: ToastType.INFO,
                expiresInMs: 7000,
                slots: {
                  button: (
                    <Button type={ButtonType.TERTIARY} className="border-2 border-b" onClick={() => reinstatePicklistItem(item)}>
                      {t('picklist.toasts.remove.undo')}
                    </Button>
                  ),
                },
              });
            });
          },
        },
      ] as ContextMenuItem[];
    },
    [hasPermission, onEditingStart, picklistSourceType, reinstatePicklistItem, t, toasts],
  );

  const setEditingTranslations = useCallback((value: Translations) => {
    setEditingItem((prev) => prev && { ...prev, translations: value });
  }, []);

  const saveEditedItem = useCallback(() => {
    onEditingEnd();
    if (!editingItem) {
      return;
    }

    PickListService.updateItem(editingItem.id, editingItem).then(() => {
      picklistSourceType && EventSystem.fireEvent('picklist-updated', { sourceType: picklistSourceType });
    });
  }, [editingItem, onEditingEnd, picklistSourceType]);

  return (
    <div className={`flex flex-col gap-4 overflow-y-auto ${className}`} data-cy="radio-button-group">
      {displayOptions.map((option) =>
        option.id !== editingItem?.id ? (
          <div className="flex items-center gap-4 rounded-[5px] bg-white p-4" key={option.id}>
            <div className="w-full min-w-0 max-w-full truncate">
              {multi ? (
                <Checkbox
                  value={option.value}
                  label={option.text}
                  description={option.subText}
                  onChange={(value) => onRadioButtonChanged(option.id, value)}
                  containerClassName="flex-grow"
                />
              ) : (
                <RadioButton
                  value={option.value}
                  label={option.text}
                  description={option.subText}
                  onChange={(value) => onRadioButtonChanged(option.id, value)}
                  className="flex-grow"
                />
              )}
            </div>
            <div className="flex-shrink-0">
              <ContextMenu items={contextItems(option)} />
            </div>
          </div>
        ) : (
          <div key={option.id} className="flex items-center gap-4 rounded-[5px] bg-white p-4">
            <div className="flex flex-grow flex-col gap-2">
              <TranslatableInput
                placeholder={t('picklist.new-item.title')}
                translationKey="name"
                translations={editingItem?.translations || {}}
                onTranslationsChange={setEditingTranslations}
              />
              {!hideNewEditorDescription && (
                <TranslatableInput
                  placeholder={t('picklist.new-item.description')}
                  translationKey="description"
                  translations={editingItem?.translations || {}}
                  onTranslationsChange={setEditingTranslations}
                />
              )}
            </div>
            <div className="flex flex-shrink-0 gap-4">
              <div
                className="bg-gray-6 hover:bg-gray-5 text-gray-2 cursor-pointer rounded-[3px]"
                onClick={() => {
                  setEditingItem(null);
                  onEditingEnd();
                }}
              >
                <XIcon className="h-8 w-8 p-1" />
              </div>
              <div
                className={`bg-gray-6 hover:bg-gray-5 rounded-[3px] ${
                  LanguageUtils.getTranslation('name', editingItem.translations).trim()
                    ? 'text-semantic-1 cursor-pointer'
                    : 'text-gray-2 cursor-not-allowed'
                } `}
                onClick={() => LanguageUtils.getTranslation('name', editingItem.translations).trim() && saveEditedItem()}
              >
                <CheckIcon className="h-8 w-8 p-1" />
              </div>
            </div>
          </div>
        ),
      )}
      <div className={`${showAddNewEditor ? 'flex' : 'hidden'} items-center gap-4 rounded-[5px] bg-white p-4`} ref={newItemContainerRef}>
        <div className="flex flex-grow flex-col gap-2">
          <TranslatableInput
            innerRef={newItemTitleRef}
            placeholder={t('picklist.new-item.title')}
            translationKey="name"
            translations={newTranslations}
            onTranslationsChange={setNewTranslations}
          />
          {!hideNewEditorDescription && (
            <TranslatableInput
              placeholder={t('picklist.new-item.description')}
              translationKey="description"
              translations={newTranslations}
              onTranslationsChange={setNewTranslations}
            />
          )}
        </div>
        <div className="flex flex-shrink-0 gap-4">
          <div
            className="bg-gray-6 hover:bg-gray-5 text-gray-2 cursor-pointer rounded-[3px]"
            onClick={() => {
              onAddNew(null);
              onEditingEnd();
            }}
          >
            <XIcon className="h-8 w-8 p-1" />
          </div>
          <div
            className={`bg-gray-6 hover:bg-gray-5 rounded-[3px] ${
              LanguageUtils.getTranslation('name', newTranslations).trim() ? 'text-semantic-1 cursor-pointer' : 'text-gray-2 cursor-not-allowed'
            } `}
            onClick={() => LanguageUtils.getTranslation('name', newTranslations).trim() && doAddNew()}
          >
            <CheckIcon className="h-8 w-8 p-1" />
          </div>
        </div>
      </div>
    </div>
  );
};
