import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormRendererInfo } from '../../../contexts/FormRendererContext';
import { ToastType, useToasts } from '../../../contexts/ToastContext';
import { Option } from '../../Option';
import { PickListModalItems } from './PickListModalItems';
import { SearchInput } from '../form-control/SearchInput';
import SkeletonLoader from '../skeleton-loader/SkeletonLoader';
import { PickListNoItems, PickListNotSetup } from './PickListModalEmptyStates';
import SorterPopup from './SorterPopup';
import { Translations } from '../../../models/Translation';
import { PickListItemType, PickListModalItemType } from './PickListTypes';
import { ModalContext } from '../../../contexts/ModalContext';
import StandardModal from '../modal/variants/StandardModal';
import { interpolateActionData } from '../../../utils/interpolation/ActionDataInterpolator';

type PickListModalProps = {
  setupError?: boolean;
  open: boolean;
  title: string;
  subTitle: string;
  items: PickListItemType[];
  loading?: boolean;
  selectedItems?: string[];
  single?: boolean;
  showAddNew?: boolean;
  hideNewEditorDescription?: boolean;
  picklistSourceType?: string;
  onClose: () => void;
  onComplete: (selectedItems: PickListItemType[]) => void;
  addCustomValue?: (translations: Translations) => string | void | Promise<string | void>;
};

const PickListModal: FC<PickListModalProps> = (props) => {
  const {
    open,
    title,
    subTitle,
    items,
    loading,
    selectedItems,
    onClose,
    onComplete,
    single,
    showAddNew,
    addCustomValue,
    setupError,
    hideNewEditorDescription,
    picklistSourceType,
  } = props;

  const [internalPicklistOptions, setInternalPicklistOptions] = useState<PickListModalItemType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const { placeholders } = useFormRendererInfo();

  const [showAddNewEditor, setShowAddNewEditor] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!open) {
      setShowAddNewEditor(false);
    }
  }, [open]);

  const { t } = useTranslation('common');
  const toasts = useRef(useToasts());

  const resetOptions = useCallback(() => {
    setInternalPicklistOptions(
      items
        .sort((itemA, itemB) => itemA.text.toLowerCase().localeCompare(itemB.text.toLowerCase()) * (sortDirection === 'asc' ? 1 : -1))
        .map((item) => ({
          ...item,
          value: !!selectedItems?.find((x) => x === item.id),
        }))
        .sort((itemA, itemB) => Number(itemB.value) - Number(itemA.value)),
    );
  }, [items, selectedItems, sortDirection]);

  const visiblityFilter = useCallback(
    (item: Option<string, boolean> & { subText?: string }) => {
      return (
        !searchValue ||
        item.text.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) > -1 ||
        (item.subText?.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) ?? -1) > -1
      );
    },
    [searchValue],
  );

  useEffect(() => {
    resetOptions();
  }, [resetOptions]);

  useEffect(() => {
    // reset selected state when closed, so next time it's correct
    if (!open) {
      setSearchValue('');
      resetOptions();
    }
  }, [open, resetOptions]);

  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [open]);

  const closeClick = (): void => {
    setSearchValue('');
    onClose();
  };

  const doneClick = (): void => {
    const selectedItems = internalPicklistOptions
      .filter((x) => x.value)
      .map((x) => ({ id: x.id, text: x.text, value: items.find((item) => item.id === x.id)?.value || '' }));

    if (single && selectedItems.length > 1) {
      toasts.current.addToast({
        title: t('picklist.selection-error.heading'),
        description: t('picklist.selection-error.subheading'),
        type: ToastType.ERROR,
        expiresInMs: 5000,
      });
      return;
    }

    onComplete(selectedItems);
    closeClick();
  };

  const internalOptionsRef = useRef(internalPicklistOptions);
  useEffect(() => {
    internalOptionsRef.current = internalPicklistOptions;
  }, [internalPicklistOptions]);

  const waitAndSelectItem = (itemId: string) => {
    // Using a ref here, otherwise it holds an instance to the old value forever, instead of the new value
    if (loading || !internalOptionsRef.current.find((x) => x.id === itemId)) {
      setTimeout(() => {
        waitAndSelectItem(itemId);
      }, 100);
      return;
    }

    setInternalPicklistOptions((prev) => prev.map((x) => (x.id === itemId ? { ...x, value: true } : x)));
  };

  const onAddNew = (translations: Translations | null) => {
    setShowAddNewEditor(false);

    if (addCustomValue && title) {
      const toSelect = addCustomValue(translations || {});
      Promise.resolve(toSelect).then((id) => {
        if (id) {
          waitAndSelectItem(id);
        }
      });
    }
    setSearchValue('');
  };

  const selectAll = (value: boolean) => {
    setInternalPicklistOptions(
      items
        .filter((item) => !searchValue || item.text.toLocaleLowerCase().indexOf(searchValue.toLocaleLowerCase()) > -1)
        .sort((itemA, itemB) => itemA.text.toLowerCase().localeCompare(itemB.text.toLowerCase()) * (sortDirection === 'asc' ? 1 : -1))
        .map((item) => ({ ...item, value })),
    );
  };

  return (
    <ModalContext.Provider value={{ open: open, onClose: onClose, modalWidth: 'w-2/5' }}>
      <StandardModal
        title={<>{interpolateActionData(title, placeholders)}</>}
        subTitle={<>{interpolateActionData(subTitle, placeholders)}</>}
        cancelButtonTitle={t('picklist.cancel-button')}
        confirmButtonTitle={t('picklist.done-button')}
        tertiaryButtonTitle={t('picklist.add-button')}
        onCancelClick={onClose}
        onConfirmClick={doneClick}
        onTertiaryButtonClick={!setupError && showAddNew ? () => setShowAddNewEditor(true) : undefined}
        confirmDisabled={isEditing}
      >
        <div className="mb-4">
          <SearchInput
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={t('picklist.search')}
            data-cy="picklist-search"
          />
        </div>

        <div className="min-h-80 rounded-md">
          {!showAddNewEditor && setupError && <PickListNotSetup />}
          {(!setupError || showAddNewEditor) && (
            <SkeletonLoader ready={!loading} type="dualBlockRow" rows={4}>
              {!showAddNewEditor &&
                internalPicklistOptions.filter(visiblityFilter).length === 0 &&
                (showAddNew ? <PickListNoItems /> : <PickListNotSetup />)}
              {(showAddNewEditor || internalPicklistOptions.filter(visiblityFilter).length > 0) && (
                <div className="bg-gray-6 p-4">
                  <div className="flex justify-between">
                    <div>
                      {!single && (
                        <div className="flex gap-4 pb-1 pl-2 text-black">
                          <span className="cursor-pointer font-medium hover:underline" onClick={() => selectAll(true)}>
                            {t('picklist.select-all')}
                          </span>
                          <span className="cursor-pointer font-medium hover:underline" onClick={() => selectAll(false)}>
                            {t('picklist.clear-all')}
                          </span>
                        </div>
                      )}
                    </div>

                    <div>
                      <SorterPopup direction={sortDirection} onChange={setSortDirection} />
                    </div>
                  </div>
                  <PickListModalItems
                    picklistSourceType={picklistSourceType}
                    hideNewEditorDescription={hideNewEditorDescription}
                    onAddNew={onAddNew}
                    showAddNewEditor={showAddNewEditor}
                    options={internalPicklistOptions}
                    visibilityFilter={visiblityFilter}
                    onChange={(values) => setInternalPicklistOptions(values)}
                    onEditingStart={() => setIsEditing(true)}
                    onEditingEnd={() => setIsEditing(false)}
                    multi={!single}
                    className="h-full max-h-96 min-h-80 rounded-md p-2"
                  />
                </div>
              )}
            </SkeletonLoader>
          )}
        </div>
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default PickListModal;
