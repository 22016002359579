import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SortAscendingIcon from '../icon/SortAscendingIcon';
import SortDescendingIcon from '../icon/SortDescendingIcon';
import SorterIcon from '../icon/SorterIcon';
import Tooltip from '../Tooltip';

type SortDirection = 'asc' | 'desc';

type LanguageSelectorProps = {
  direction: SortDirection;
  onChange: (direction: SortDirection) => void;
};

const SorterPopup: FC<LanguageSelectorProps> = (props) => {
  const { direction, onChange } = props;
  const [open, setOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common');

  const onClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault();
    setOpen(!open);
  };

  useEffect(() => {
    const mouseDownListener = (e: MouseEvent): void => {
      if (popupRef.current && !popupRef.current.contains(e.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', mouseDownListener);

    return () => {
      document.removeEventListener('mousedown', mouseDownListener);
    };
  }, []);

  return (
    <div ref={popupRef}>
      <Tooltip text={t('picklist.sorter.tooltip')}>
        {(tooltip) => (
          <div {...tooltip} className="relative flex cursor-pointer select-none items-center" onClick={onClick}>
            <SorterIcon direction={direction} className="text-gray-3 hover:text-gray-1 h-6 w-6" />
          </div>
        )}
      </Tooltip>
      {open && (
        <div className="absolute right-0 px-2 py-1" onClick={() => setOpen(false)}>
          <ul className="border-1 w-44 border-gray-200 bg-white shadow-md">
            <div className="hover:bg-gray-6 flex cursor-pointer items-center gap-4 px-4 py-2" onClick={() => onChange('asc')}>
              <SortAscendingIcon className="h-6 w-6" />
              {t('picklist.sorter.asc')}
            </div>
            <div className="hover:bg-gray-6 flex cursor-pointer items-center gap-4 px-4 py-2" onClick={() => onChange('desc')}>
              <SortDescendingIcon className="h-6 w-6" />
              {t('picklist.sorter.desc')}
            </div>
          </ul>
        </div>
      )}
    </div>
  );
};

export default SorterPopup;
