import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../icon/SearchIcon';

const EmptyState: FC<{ type: 'searching' | 'missing-items' | 'setup' }> = (props) => {
  const { type } = props;

  const { t } = useTranslation('common');
  return (
    <div className="mx-auto mt-[70px] flex max-w-[60%] flex-col items-center gap-4 text-center">
      <SearchIcon className="bg-gray-6 h-24 w-24 rounded-full p-6" />
      <span className="font-medium">{t(`picklist.empty.${type}.heading`)}</span>
      <span className="text-dpm-14">{t(`picklist.empty.${type}.description`)}</span>
    </div>
  );
};

export const PickListNoSearchResults: FC = () => {
  return <EmptyState type="searching" />;
};

export const PickListNotSetup: FC = () => {
  return <EmptyState type="setup" />;
};
export const PickListNoItems: FC = () => {
  return <EmptyState type="missing-items" />;
};
